import React, { useEffect, useState } from 'react';
import logo from './logo.svg'
// import './App.css';

import './gridform.css';
import './style.css';
import './mobile.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  
} from "react-router-dom";

import firebase from 'firebase'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/auth'
import axios from 'axios'
import MainPage from './MainPage'
import WaitingList from './WaitingList'
import NewPage from './NewPage'

// view profile
// next.js

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Home(props) {
  useEffect(() => {
    if (props.userLoaded && props.user ) {
      props.history.push('/profile')
      // if (props.user.username) {
      //   props.history.push('/profile/' + props.user.username)
      // } else {
      //   props.history.push('/username')
      // }
    }
  }, [props.user, props.userLoaded])

  return (
    <div className='main'>
      
      <div className='container'>

        <div className="introText">
        <h1 ><div class="center mylogo"><img src="https://files-1l82xznr2.now.sh/mykidsu.png"/></div></h1>
        <p>this is just the beginning of our experimental online platform that includes, things from a kidsuper chat to the ability to create a web-store and portfolio.
 
 We also don't know how to run a perfect company so the platform is built to be agile and wants as much feedback as possible, 
 we can all learn what the most important digital things for creatives are and have tools tailored and optimized towards them.
 </p>
 <p>think about this as something like tumblr mixed with Big Cartel meets SquareSpace. There will be things like playlists & moodboards, and integrations with other apps. This online world of ours will be just as random as we our offline.</p>
 
<img src={logo} alt="" className='logo'/>
</div>
        <div className='full-width-form'>
<h2>Step 1. Enter Your Email</h2>

          <div className='fast-button'>
            <iframe
              title="loginButton"
              src="https://api.fast.co/api/buttonframe2?key=MyK4WxR7PVzNroKgJaoGb52egL03lkYX"
              // src="https://api.fast.co/api/buttonframe2?key=9V8O1NeGR3W4loGVDEZjyXYQPr5xLDq2" // dev
            />
          </div>
        </div>
      </div>
    </div>
  )
}


function Login(props) {
  let query = useQuery();

  useEffect(() => {
    if (props.userLoaded && props.user ) {
      props.history.push('/profile')

      // if (props.user.username) {
      //   console.log(props.user)
      //   props.history.push('/profile/' + props.user.username)
      // } else {
      //   props.history.push('/username')
      // }
    }
  }, [props.user, props.userLoaded])

  useEffect(() => {
    const identifier = query.get('identifier')
    const oth = query.get('oth')
    const challengeId = query.get('challengeId')

    // axios.get('http://localhost:5000/my-kidsuper/us-central1/helloWorld', {
      axios.get('https://us-central1-my-kidsuper.cloudfunctions.net/helloWorld', {
      params: {
        identifier,
        oth,
        challengeId
      }
    })
    .then(res => {
      if (res.data.success) {
        firebase.auth().signInWithCustomToken(res.data.token)
        .catch(function(error) {
          console.log(error)
        })
      } else {
        alert(res.data.message)
      }
    })
    .catch(e => console.log(e))
  }, [query])

  return (
    <div class="center">
 loading ...
    </div>
  )
}

function App() {
  const [user, setUser] = useState(null)
  const [userLoaded, setUserLoaded] = useState(false)

  useEffect(() => {
     firebase.auth().onAuthStateChanged((user) => {
    // firebase.auth().onAuthStateChanged(() => { // comment this line
    //   const user = {uid: '1r7BrjiKZZP7jscU6RQqQDE4rn72'} // comment this line
      if (user) {
        firebase.database().ref('Users/' + user.uid)
        .on('value', s => {

          const data = s.val() || {}
          setUser({...data, uid: user.uid})
          setUserLoaded(true)

        })
        
      } else {
        setUser(null)
        setUserLoaded(true)
      }
    })
  }, [])

  return (
    <div className="App">
      <Router>
        <div>
          <Switch>
            <Route path="/login" render={routeProps =>
              <Login {...routeProps} user={user} userLoaded={userLoaded}/>
            } />
            <Route path="/profile" render={routeProps =>
              <MainPage {...routeProps} user={user} userLoaded={userLoaded}/>
            } />
            <Route path="/new-page" render={routeProps =>
              <NewPage {...routeProps} user={user} userLoaded={userLoaded}/>
            } />
            
            <Route path="/waiting-list" render={routeProps =>
              <WaitingList
                {...routeProps} user={user} userLoaded={userLoaded}
                onSignOut={() => 
                  firebase.auth().signOut()
                  .then(() => routeProps.history.push('/'))
                }
              />
            } />

            <Route exact path="/" render={routeProps =>
              <Home {...routeProps} user={user} userLoaded={userLoaded}/>
            } />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
