import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyBJqQwFi9nTFiMSGOxcU2AP143sitaM3bc",
  authDomain: "my-kidsuper.firebaseapp.com",
  databaseURL: "https://my-kidsuper.firebaseio.com",
  projectId: "my-kidsuper",
  storageBucket: "my-kidsuper.appspot.com",
  messagingSenderId: "386094377059",
  appId: "1:386094377059:web:124a76f89f2420828db056",
  measurementId: "G-LN1PR2FPBM"
};

// Initialize Firebase
// const app = 
firebase.initializeApp(firebaseConfig);
// app.functions().useFunctionsEmulator('http://localhost:5000')

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
