import React, { useState, useEffect } from 'react'
import { Twitter } from 'react-social-sharing'
import firebase from 'firebase'
import getContrastRatio from 'get-contrast-ratio';
import 'firebase/database'

function WaitingList({ history, user = {}, onSignOut, userLoaded}) {
  const [number, setNumber] = useState(null)

  useEffect(() => {
    if (userLoaded) {
      if (user) {
        firebase.database().ref('Users')
        .once('value')
        .then(s => {
          const users = s.val()

          const mapped = Object.keys(users).map(key => ({userId: key, ...users[key]}))
          mapped.sort((a, b) => a.createdAt - b.createdAt < 0 ? -1 : 1)
          const index = mapped.findIndex(item => item.userId === user.uid)

          setNumber(index + 1)
        })
      } else {
        history.push('/')
      }
    }
  }, [userLoaded])

  if (!userLoaded) {
    return false
  }
  let isInverted = false
  if (user.color && (getContrastRatio('white', user.color) > getContrastRatio('black', user.color))) {
    isInverted = true
  }

  return (
    <div className="outside" style={user.color ? {backgroundColor: user.color} : {}}>
      <div className={`${isInverted ? 'inverted' : ''}`}>
        <div className="header">

        <br/>
        <h1>@{user.username}</h1>
        <button className="google" onClick={() => history.push('/profile')}>
          <h3>edit profile</h3>
        </button>
        <br/>
          <div className="sign-in-container ">

          {/* tweet this shit! <img src="https://icon.now.sh/arrow"/>
          <Twitter simple message={'Im # ' + number + ' on the just.is waitlist.' || '...' } /> 
           */}
          </div>

        </div>

        <div className="sign-in-form wait">
        <h1> You are<br /><b>#{number || '...'}</b>  on the waitlist</h1>

        <br/>

        <button className="google"  onClick={onSignOut}>Log out and reserve another username</button>
          <div className="sign-up-container">
          </div>

        </div>

      </div>  
    </div>
  )
}

export default WaitingList
