import React, { useEffect } from 'react'

function NewPage(props) {
  useEffect(() => {
    if (props.userLoaded && !props.user) {
      props.history.push('/')
    }
  }, [props.user, props.userLoaded])

  return (
    <div>
      Auth protected
    </div>
  )
}

export default NewPage
