import React, { useState, useEffect} from 'react'

import firebase from 'firebase'
import 'firebase/database'
import getContrastRatio from 'get-contrast-ratio';
import { ChromePicker } from 'react-color'

const CustomCheckbox = ({checked, label, onClick}) =>
  <div onClick={onClick} style={{display: 'inline-block'}}>
    <input className="styled-checkbox" type="checkbox" checked={checked} />
    <label for="styled-checkbox-1"> {label} &nbsp;</label>
  </div>


function MainPage(props) {

  const [selectedShoesSize, setSelectedShoesSize] = useState([])
  const [selectedTeesSize, setSelectedTeesSize] = useState([])
  const [selectedShoesWearType, setSelectedShoesWearType] = useState([])
  const [selectedTopSize, setSelectedTopSize] = useState([])
  const [selectedTopType, setSelectedTopType] = useState([])
  const [sex, setSex] = useState('')

  const [pantsWidth, setPantsWidth] = useState([])
  const [pantsLength, setPantsLength] = useState([])

  const [pantsWearType, setPantsWearType] = useState([])

  const [brands, setBrands] = useState([''])

  const [username, setUsername] = useState('')
  const [color, setColor] = useState({
    hex: '#ffffff'
  })
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  const [teeFit, setTeeFit] = useState([])
  const [teeStyle, setTeeStyle] = useState([])

  const [topFit, setTopFit] = useState([])
  const [pantsFit, setPantsFit] = useState([])


  const popover = {
    position: 'absolute',
    zIndex: '2',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }


  useEffect(() => {
    if (props.userLoaded) {
      if (props.user) {
        if (props.user.username) {
          setUsername(props.user.username)
        }
        if (props.user.color) {
          setColor({hex: props.user.color})
        }
        if (props.user.shoesSize) {
          setSelectedShoesSize(props.user.shoesSize)
        }
        if (props.user.teesSize) {
          setSelectedTeesSize(props.user.teesSize)
        }
        if (props.user.shoesWearType) {
          setSelectedShoesWearType(props.user.shoesWearType)
        }
        if (props.user.topSize) {
          setSelectedTopSize(props.user.topSize)
        }
        if (props.user.topType) {
          setSelectedTopType(props.user.topType)
        }
        if (props.user.pantsWidth) {
          setPantsWidth(props.user.pantsWidth)
        }
        if (props.user.pantsLength) {
          setPantsLength(props.user.pantsLength)
        }
        if (props.user.pantsWearType) {
          setPantsWearType(props.user.pantsWearType)
        }
        if (props.user.brands) {
          setBrands(props.user.brands)
        }
        if (props.user.sex) {
          setSex(props.user.sex)
        }
        if (props.user.teeFit) {
          setTeeFit(props.user.teeFit)
        }
        if (props.user.teeStyle) {
          setTeeStyle(props.user.teeStyle)
        }
        if (props.user.topFit) {
          setTopFit(props.user.topFit)
        }
        if (props.user.pantsFit) {
          setPantsFit(props.user.pantsFit)
        }
      } else {
        props.history.push('/')
      }
    }
  }, [props.user, props.userLoaded])

  function handleChange(value, data, cb) {
    if (data.includes(value)) {
      const index = data.indexOf(value)

      cb([
        ...data.slice(0, index),
        ...data.slice(index + 1)
      ])
    } else {
      cb([
        ...data,
        value
      ])
    }
  }

  function handleBrandChange(newValue, index) {
    const copy = brands.slice()

    copy[index] = newValue

    if (index === (copy.length - 1) && newValue) {
      copy.push('')
    }

    setBrands(copy)
  }

  async function onSave() {
    if (!color.hex) {
      return alert('Fill color')
    }

    if (!username) {
      return alert('Fill username')
    }

    let isAvailable = true

    if (props.user.username !== username) {
      isAvailable = await firebase.database().ref('Users')
                            .orderByChild('usernameLowercased')
                            .equalTo(username.toLowerCase())
                            .once('value')
                            .then(s => !s.val())
    }

    if (!isAvailable) {
      return alert('This username is taken. Try another one.')
    }
  
    firebase.database().ref('Users/' + props.user.uid).update({
      shoesSize: selectedShoesSize,
      teesSize: selectedTeesSize,
      shoesWearType: selectedShoesWearType,
      topSize: selectedTopSize,
      topType: selectedTopType,
      pantsWidth,
      pantsLength,
      pantsWearType,
      brands,
      username,
      usernameLowercased: username.toLowerCase(),
      teeFit,
      teeStyle,
      topFit,
      pantsFit,
      sex: sex || null,
      color: color.hex
    })
    .then(() => props.history.push('/waiting-list'))
  }

  const teeSizes = ['XS', 'S', 'M', 'L', 'XL', '+XXL']
  const topSizes = ['XS', 'S', 'M', 'L', 'XL', '+XXL']
  const wearType = ['normal', 'baggy', 'tight']
  const topType = ['hoodies', 'regular', 'big jackets', 'fancy', 'jackets']
  const shoesTypes = ['Runners', 'Boots', 'Dressy', 'Laceless/Clogs', 'Sandals']

  const userColor = color && color.hex

  let isInverted = false

  if (userColor && (getContrastRatio('white', userColor) > getContrastRatio('black', userColor))) {
    isInverted = true
  }

  return (
    <div className="outside" style={{backgroundColor: userColor || 'grey'}}>
      <div class="center mylogo"><img src="https://files-1l82xznr2.now.sh/mykidsu.png"/></div>
   <div className={`${isInverted ? 'inverted' : ''}`}>
    <form className="grid-form" >
      <div className="insides">
      <fieldset>
        <legend class="big">Step 2. Pick Your Username & Add to Profile</legend>
          <div data-row-span="1">
            <div data-row-span="6">
              <div data-field-span="2">
                <label>@username</label>
                <input
                  type="text"
                  placeholder="KidSuper"
                  className='input-username'
                  style={{backgroundColor: 'transparent'}}
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                />
              </div>
              <div class="subtexts" data-field-span="2">
                <label>sub-domain</label>
                {username}.kidsuper.com
              </div>
              <div data-field-span="2">
                <label>Profile Color</label>
                <button
                  className='button-small-text'
                  onClick={(e) => {
                    e.preventDefault()
                    setDisplayColorPicker(true)
                  }}
                >
                  {color && <div  className="cborder" style={{height: '10px', width: '10px', backgroundColor: color.hex, border:'2px solid', display: 'inline-block'}} />}
                &nbsp;  {color.hex}
                </button>
                { displayColorPicker
                  ? <div style={ popover }>
                      <div  style={ cover } onClick={() => setDisplayColorPicker(false)}/>
                      <ChromePicker color={ color.rgb } onChange={(e) => setColor(e)} />
                    </div>
                  : null
                }
              </div>
            </div>
          </div>
          <legend class="small">Everythting below here is <i>*optional </i> but it helps us with making things for you. and betters your chances of getting items in your size.</legend>
          <div data-row-span="6" class="tsizes">
            <div data-field-span="1">
              <label>Shoe Size </label>
              <input placeholder="10" type="number" min="4" max="16" step="0.5" value={selectedShoesSize}
                onChange={e => setSelectedShoesSize(e.target.value)}
              />
            </div>
            <div data-field-span="1">
              <label>Mens or Womens</label>
              <CustomCheckbox
                onClick={() => setSex('M')}
                checked={sex === 'M'}
                label={'M'}
              />
              <CustomCheckbox
                onClick={() => setSex('W')}
                checked={sex === 'W'}
                label={'W'}
              />
            </div>
            <div data-field-span="4" >
              <label>Types of Shoes</label>
              {shoesTypes.map((type, i) =>
                <CustomCheckbox
                  key={i}
                  onClick={() => handleChange(type, selectedShoesWearType, setSelectedShoesWearType)}
                  checked={selectedShoesWearType.includes(type)}
                  label={type}
                />
              )}
            </div>
          </div>
          <div data-row-span="12" class="tsizes">
            <div data-field-span="4"  >
              <label>Tee Size</label>
              {teeSizes.map((size, i) =>
                <CustomCheckbox
                  key={i}
                  onClick={() => handleChange(size, selectedTeesSize, setSelectedTeesSize)}
                  checked={selectedTeesSize.includes(size)}
                  label={size}
                />
              )}
            </div>
        
            <div data-field-span="3">
              <label>Tee Fit</label>

              {wearType.map((type, i) =>
                <CustomCheckbox
                  key={i}
                  onClick={() => handleChange(type, teeFit, setTeeFit)}
                  checked={teeFit.includes(type)}
                  label={type}
                />
              )}
            </div>
            <div data-field-span="5">
              <label>Tee Style</label>
              
              {['Graphic', 'Longsleeve', 'Pocket', 'Distressed/Vintage'].map((size, i) =>
                <CustomCheckbox
                  key={i}
                  onClick={() => handleChange(size, teeStyle, setTeeStyle)}
                  checked={teeStyle.includes(size)}
                  label={size}
                />
              )}
            </div>
          </div>
          <div data-row-span="12" class="tsizes">
            <div data-field-span="4">
              <label>Top Size</label>
              {topSizes.map((type, i) =>
                <CustomCheckbox
                  key={i}
                  onClick={() => handleChange(type, selectedTopSize, setSelectedTopSize)}
                  checked={selectedTopSize.includes(type)}
                  label={type}
                />
              )}
            </div>
              <div data-field-span="3">
              <label>Top Fit</label>
              {wearType.map((type, i) =>
                <CustomCheckbox
                  key={i}
                  onClick={() => handleChange(type, topFit, setTopFit)}
                  checked={topFit.includes(type)}
                  label={type}
                />
              )}
            </div>
            <div data-field-span="5">
              <label>Types of Tops</label>
              {topType.map((type, i) =>
                <CustomCheckbox
                  key={i}
                  onClick={() => handleChange(type, selectedTopType, setSelectedTopType)}
                  checked={selectedTopType.includes(type)}
                  label={type}
                />
              )}
            </div>
          </div>
          <div data-row-span="8" class="tsizes bbot">
              <div data-field-span="1">
                <label>Pants Width </label>
                <input
                  type="number"
                  
                  placeholder="W"
                  value={pantsWidth}
                  onChange={e => setPantsWidth(e.target.value)}
                />
              </div>
              <div data-field-span="1">
                <label>Pants Length </label>
                <input
                  type="number"
                  placeholder="L"
              
                  value={pantsLength}
                  onChange={e => setPantsLength(e.target.value)}
                />
              </div>
              <div data-field-span="2">
                <label>Pants Fit</label>
                
                {wearType.map((type, i) =>
                  <CustomCheckbox
                    key={i}
                    onClick={() => handleChange(type, pantsFit, setPantsFit)}
                    checked={pantsFit.includes(type)}
                    label={type}
                  />
                )}
              </div>
              <div data-field-span="4">
              <label>Types of Pants</label>
              {['Sweats/Track', 'Denim', 'Khaki/Cords', 'Shorts/Capri'].map((type, i) =>
                <CustomCheckbox
                  key={i}
                  onClick={() => handleChange(type, pantsWearType, setPantsWearType)}
                  checked={pantsWearType.includes(type)}
                  label={type}
                />
              )}
            </div>
            </div>

        <fieldset class="itemsList">
          <legend class="small">What brands do you like or items should we make, or features of the platform you want to see?</legend>
          <div className="noBorder" data-row-span="2">
            <div data-field-span="2">
              {brands.map((brand, i) =>
                <div>
                  <input
                    key={i}
                    placeholder="Item or Brand or Anything haha."
                    row={1}
                    type='text'
                    style={{border: '1px solid #000', marginBottom: '1px'}}
                    // className='size-profile_suggest-input' 
                    value={brand} 
                    onChange={e => handleBrandChange(e.target.value, i)}
                  />
                  {i !== 0 &&
                    <button class="deleteBtn" onClick={() => {
                      const copy = brands.slice()
                      copy.splice(i, 1)

                      setBrands(copy)
                    }}>x</button>
                  }
                </div>
                
              )}
            </div>
          </div>
        </fieldset>
        <br/><br/>
     

      </fieldset>
  
      </div>
      <div className="center">
      <button className="save-btn google" onClick={e => {
          e.preventDefault()
          onSave()
        }}>
          save
        </button>
        </div>
    </form>
    </div>
    </div>
  )
}

export default MainPage

// TYPES OF SHOES
// TEE FIT
// TEE STYLE
// TYPES OF TOPS
// TYPES OF PANTS
// brands